

const ArrowDropDownIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`${className}`} width="21" height="20" viewBox="0 0 20 20" fill="none">
               <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" />
          </svg>
     )
}

export default ArrowDropDownIcon;
