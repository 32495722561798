import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import {
    CustomColumnHeader,
    ReorderColumnsPopover,
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
    CustomFilterSection
} from "@/Components/Display/Table/Custom/Components";
import { TabContainerButton } from "@/Components/Navigation";
import { Button } from "@/Components/Inputs";
import { AgGridIcon, Table, Tag } from "@/Components/Display";
import { RefreshIcon, ChangeCircleIcon } from "@/Icons";
import { requestMyBatches } from "@/Store/Actions";
import { withStore } from "@/Helpers/withStore";
import { getColumnState, setColumnState } from "@/Helpers/TableColumnHelper";

class MyBatchesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            value: 0,
            isClaimItemsDialogOpen: false,
            selectedSerialNumbers: [],
            showAddKitsDialog: false,
            selectedPartNumber: '',
            addItemRowData: [
                {
                    id: 0,
                    checked: false,
                    orderNumber: '',
                    serialNumber: '',
                    boxSerial: ''
                }
            ],
            isRefreshing: false,
            isToastOpen: false,
            isGridLoading: true,
            columnDefs: props.myBatchesColumnDefs
        };
    };

    gridOptions = () => {
        return {
            groupDisplayType: 'custom',
            getRowHeight: params => params.node.group ? 48 : 40,
            onFilterChanged: (params) => this.handleShowNoOverlay(params),
            onGridReady: ({ api }) => {
                this.props.handleGridApiSetState(api);
            },
            onColumnMoved: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnVisible: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnResized: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            icons: {
                groupExpanded: () => AgGridIcon('ArrowDropUpIcon', { className: 'text-primary' }),
                groupContracted: () => AgGridIcon('DropDownIcon', { className: 'text-primary' }),
            },
            popupParent: document.querySelector('body'),
            getRowClass: params => {
                if (params.node.rowPinned) {
                    return 'ag-row-pinned';
                }
            },
            postProcessPopup: params => {
                // check callback is for columnFilter
                if (params.type !== 'columnFilter') {
                    return;
                }

                // Calculate the position based on the column header width
                const eventSource = params.eventSource;
                const ePopup = params.ePopup;
                const popupWidth = ePopup.offsetWidth;
                const headerRect = eventSource.getBoundingClientRect();
                const popupLeft = headerRect.right - popupWidth + window.scrollX;

                if (popupLeft > 0) {
                    // Consider header padding
                    ePopup.style.left = `${popupLeft + 12}px`;
                }

                ePopup.style.width = `220px`;
                if (params?.column?.userProvidedColDef?.headerName === 'Date') {
                    ePopup.style.maxWidth = '360px';
                    ePopup.style.minWidth = '360px';
                }
            },
            suppressLoadingOverlay: true,
            getRowStyle: params => {
                if (params?.node?.group) {
                    if (params?.node?.rowIndex === 0) {
                        return {
                            background: '#E2F3FF',
                            'border': '0px',
                        }
                    }
                    return {
                        background: '#E2F3FF',
                        borderTop: '1px solid #77D1FF',
                        borderBottom: '0px'
                    }
                }
                if (params?.node?.rowIndex % 2 === 0) {
                    return { background: '#ffffff', border: '0px' };
                } else {
                    return { background: '#EFF4F9', border: '0px' }
                }
            }
        };
    };

    getDefaultColDef = () => {
        return {
            rowHeight: 50,
            flex: 1,
            filter: true,
            suppressHeaderMenuButton: true,
            menuTabs: [],
            minWidth: 220,
            headerComponent: CustomColumnHeader,
            headerComponentParams: {
                gridRef: this.props.myBatchesGridRef
            },
            resizable: false,
            suppressMovable: true,
            cellStyle: params => {
                if (params?.node?.rowPinned) {
                    return { 'border': '0px' };
                }
                if (!params?.node?.group) {
                    return { 'border': '1px solid #EAEEF3' };
                }
                return null;
            }
        };
    };

    handleRefreshClick = async () => {
        if (this.props.myBatchesGridApi) {
            this.setState({
                isRefreshing: true,
                isGridLoading: true
            }, async () => {
                await this.fetchMyBatches();
                this.setState({
                    isRefreshing: false,
                    isGridLoading: false
                });
            });
        };
    };

    formatToTags = (props) => {
        return <Tag className={props.getValue()} label={props.getValue()} />
    };

    handleRowDoubleClicked = ({ node, data }) => {
        if (node?.footer) return;
        this.props.history?.push('/batch-details', {
            batchId: data?.batchID,
            itemID: data?.itemID
        });
    };

    fetchMyBatches = async () => {
        if (!this.props.userID) {
            this.setState({
                isGridLoading: false
            });
            return;
        }
        try {
            await this.props.requestMyBatches(this.props.userID);
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isGridLoading: false
            });
        };
    };

    handleShowNoOverlay = params => params.api.getDisplayedRowCount() === 0 ? this.props.myBatchesGridApi.showNoRowsOverlay() : this.props.myBatchesGridApi.hideOverlay();

    async componentDidMount() {
        const getState = getColumnState(this.props.currentTab, this.state.columnDefs);

        if (getState) {
            this.setState({ columnDefs: getState });
        }

        await this.fetchMyBatches();
    };

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps?.myBatches !== this.props.myBatches) {
            this.setState({
                rowData: this.props.myBatches
            });
        }

        if (this.props.myBatchesGridApi) {
            if (this.state.isGridLoading) {
                this.props.myBatchesGridApi?.showLoadingOverlay();
            } else {
                this.props.myBatchesGridApi?.hideOverlay();
            }
        }

        if (prevProps?.userID !== this.props.userID) {
            await this.fetchMyBatches();
        }
    };

    renderResetFilterButton = () => {
        const filterRef = this.props.myBatchesFilterGridApiRef.current;
        const myBatchesGridApi = this.props.myBatchesGridApi;
        const filterDateRef = this.props.myBatchesFilterDateGridApiRef.current;
        const resetSortModel = this.props.sortModelResetRef.current;
        const reOrderResetRef = this.props.reOrderResetRef.current;

        const handleResetFilters = () => {
            if (filterRef && myBatchesGridApi && filterDateRef && resetSortModel && reOrderResetRef) {
                myBatchesGridApi.setFilterModel(null);
                myBatchesGridApi.resetColumnState();

                const colIds = myBatchesGridApi.getColumnDefs().map(item => (item.colId));

                colIds?.forEach((colId) => {
                    filterRef.getFilterInstance(colId, (filterInstance) => {
                        if (filterInstance) {
                            const currentFunctions = filterInstance.params.gridApiRef?.current;
                            if (currentFunctions) {
                                currentFunctions.resetSelectedOptions();
                            }
                        }
                    });
                });
                filterDateRef.getFilterInstance('createDate', (filterInstance) => {
                    if (filterInstance) {
                        const currentFunctions = filterInstance.params.gridApiRef?.current;
                        if (currentFunctions) {
                            currentFunctions.resetDateSelected();
                        }
                    }
                });
                resetSortModel.resetFilterModel();
                reOrderResetRef.resetReOrderModel();
            }
        };

        return (
            <Button
                variant="flat"
                className="w-[152px]"
                startIcon={<ChangeCircleIcon className="text-on-surface-variant" />}
                onClick={handleResetFilters}
            >
                Reset Filters
            </Button>
        );
    };

    render() {
        const { isRefreshing, rowData, isGridLoading, columnDefs } = this.state;
        const {
            myBatchesGridApi,
            myBatchesGridRef,
            myBatchesColumnDefs,
            currentTab,
            myBatchesFilterGridApiRef,
            sortModelResetRef,
            reOrderResetRef
        } = this.props;

        return (
            <div>
                <div className="flex justify-between py-2 pt-3 mb-2">
                    <TabContainerButton>
                        <ReorderColumnsPopover
                            gridApi={myBatchesGridApi}
                            columns={myBatchesColumnDefs}
                            currentTab={currentTab}
                            reOrderResetRef={reOrderResetRef}
                        />
                        {this.renderResetFilterButton()}
                    </TabContainerButton>
                    <TabContainerButton>
                        <Button
                            className="w-[122px]"
                            startIcon={<RefreshIcon />}
                            isLoading={isRefreshing}
                            onClick={this.handleRefreshClick}
                        >
                            Refresh
                        </Button>
                    </TabContainerButton>
                </div>
                <CustomFilterSection
                    api={myBatchesGridApi}
                    gridApiRef={myBatchesFilterGridApiRef}
                    columns={myBatchesColumnDefs}
                    sortModelResetRef={sortModelResetRef}
                    currentTab={currentTab}
                />
                <div className="border rounded-[10px] shadow-custom">
                    <Table
                        className="my-batches-table"
                        reactiveCustomComponents
                        ref={myBatchesGridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={this.getDefaultColDef()}
                        suppressMenuHide
                        columnMenu="new"
                        rowGroupPanelSuppressSort
                        gridOptions={this.gridOptions()}
                        suppressLoadingOverlay={false}
                        rowSelection="single"
                        onRowDoubleClicked={this.handleRowDoubleClicked}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        suppressNoRowsOverlay={isGridLoading}
                        loadingOverlayComponent={CustomLoadingOverlay}
                        suppressContextMenu
                        grandTotalRow="bottom"
                    />
                </div>
            </div>
        );
    }
};

const mapStateToProps = ({ user, batch, orders }) => {
    return {
        userID: user?.user?.userID,
        myBatches: batch?.myBatches,
        myBatchesColumnItems: orders?.myBatchesColumnItems
    };
};

const mapDispatchToProps = ({ batch }) => {
    return {
        requestMyBatches: userID => batch.dispatch(requestMyBatches(userID))
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MyBatchesPage));
