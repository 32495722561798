import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { CloseIcon } from "@/Icons";
import SingleSortAscendingIcon from "@/Icons/SingleSortAscendingIcon";
import SingleSortDescendingIcon from "@/Icons/SingleSortDescendingIcon";

const unFilteredColumns = ['createDate'];

const nonSelectionFilter = {
    total: 'total',
    createDate: 'createDate'
}

export const CustomChip = ({ className, chipItem, deleteFunction }) => {

    if (deleteFunction === 'function' || !chipItem) {
        return;
    }

    return (
        <div
            className={`
                flex h-8 rounded-[5px] space-x-1 items-center px-2 ${className ?? ''}
            `}
            key={chipItem?.key || ''}
        >
            <p className="font-roboto text-xs tracking-[0.5px] text-on-primary-container">
                {chipItem.name}
            </p>
            {chipItem.direction === 'asc' &&
                <div className="size-6">
                    <SingleSortAscendingIcon />
                </div>
            }
            {chipItem.direction === 'desc' &&
                <div className="size-6">
                    <SingleSortDescendingIcon />
                </div>
            }
            <CloseIcon className="cursor-pointer !size-4" onClick={() => deleteFunction()} />
        </div>
    );
};

const CustomFilterSection = ({ api, sortModelResetRef, currentTab, gridApiRef }) => {

    const [filterSortModel, setFilterSortModel] = useState(null);
    const [filterModel, setFilterModel] = useState([]);

    const resetFilterSortModel = useCallback((colId) => {
        setFilterSortModel(null);
        localStorage.removeItem(`filterSort-${currentTab}`);
        if (colId && api) {
            const getColumnState = api.getColumnState().map(item => ({ ...item, sort: item.colId === colId ? null : item.sort }));
            api.applyColumnState({
                state: getColumnState,
            });
        }
    }, [currentTab, api]);

    const resetFilterModel = () => {
        setFilterModel([]);
        localStorage.removeItem(`filter-${currentTab}`);
        resetFilterSortModel();
    };


    const deleteFilterItem = useCallback((itemToDelete) => {
        const colId = itemToDelete.colId;
        const columnName = itemToDelete.colId === nonSelectionFilter.total ? nonSelectionFilter.total : itemToDelete.name;

        if (api && colId && columnName) {
            api.getFilterInstance(colId, (filterInstance) => {
                if (filterInstance) {
                    if (columnName.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                        filterInstance.sourceParams.gridApiRef.current?.resetSearchFilter();
                    } else {
                        filterInstance.sourceParams.gridApiRef.current?.onSelectedOptionChanged(columnName);
                    }
                }
            });
            setFilterModel((prevFilterModel) => {
                return prevFilterModel?.filter(item => {
                    return item.name !== itemToDelete.name;
                });
            });
        };
    }, [api]);

    useEffect(() => {
        if (api) {
            const handleFilterChipsUpdate = () => {
                api.eventService.addEventListener('filterChanged', ({ columns, api }) => {
                    if (columns && columns.length > 0) {
                        const colId = columns[0]?.colId;
                        const applyFilter = !unFilteredColumns.includes(colId.toLowerCase());
                        if (applyFilter) {
                            const getFilterModel = api.getFilterModel();
                            const flattenedFilterModel = [];
                            Object.keys(getFilterModel)?.forEach(key => {
                                if (key.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                                    const model = {
                                        name: getFilterModel[key],
                                        colId: key,
                                        filter: true
                                    }
                                    flattenedFilterModel.push(model);
                                } else {
                                    getFilterModel[key].forEach(value => {
                                        flattenedFilterModel.push({
                                            name: value,
                                            colId: key,
                                            filter: true
                                        });
                                    });
                                }
                            });
                            setFilterModel(flattenedFilterModel);
                        };
                    }
                });

                api.eventService.addEventListener('sortChanged', ({ api }) => {

                    const getColumnState = api.getColumnState().filter(item => item.sort);
                    const isCompanyAndNotAllowed = !(getColumnState.length === 2 && getColumnState[0].colId === 'Company Name');

                    if (getColumnState.length > 1 && isCompanyAndNotAllowed) {
                        return;
                    }

                    const getSortedItem = api.getColumnState().find((item) => item.sort);

                    if (!getSortedItem) {
                        resetFilterSortModel();
                    }
                    const getColumn = api.getAllDisplayedColumns().find(item => item.colId === getSortedItem.colId);
                    if ((getColumn.sort === 'asc' || getColumn.sort === 'desc')) {
                        const itemToAdd = { name: getColumn.colDef.headerName, direction: getColumn.sort, colId: getColumn.colId };
                        setFilterSortModel(itemToAdd);
                    }
                })
            };
            handleFilterChipsUpdate();
            api.eventService.addEventListener('sortChanged', handleFilterChipsUpdate);
            api.eventService.addEventListener('filterChanged', handleFilterChipsUpdate);

            return () => {
                api.eventService?.removeEventListener('filterChanged', handleFilterChipsUpdate);
                api.eventService?.removeEventListener('sortChanged', handleFilterChipsUpdate);
            };
        }
    }, [api, deleteFilterItem, currentTab, resetFilterSortModel]);


    useImperativeHandle(sortModelResetRef, () => ({
        resetFilterModel
    }));

    useEffect(() => {
        if (filterModel.length > 0) {
            try {
                const filterState = JSON.stringify(filterModel);
                localStorage.setItem(`filter-${currentTab}`, filterState);
            } catch (error) {
                console.log(error);
            };
        }

        if (filterSortModel) {
            try {
                const filterState = JSON.stringify(filterSortModel);
                localStorage.setItem(`filterSort-${currentTab}`, filterState);
            } catch (error) {
                console.log(error);
            };
        }
    }, [filterModel, filterSortModel, currentTab]);

    const onFirstDataRendered = useCallback(() => {
        if (api) {
            return new Promise((resolve) => {
                const handleFirstDataRendered = () => {
                    resolve();
                    api?.removeEventListener('firstDataRendered', handleFirstDataRendered);
                };
                api.addEventListener('firstDataRendered', handleFirstDataRendered);
            });
        } else {
            return Promise.reject(new Error('grid API is not available.'));
        }
    }, [api]);

    useEffect(() => {
        try {
            const savedFilterState = JSON.parse(localStorage.getItem(`filter-${currentTab}`)) || [];

            if (savedFilterState.length > 0 && api && gridApiRef.current) {
                savedFilterState.forEach(async item => {
                    const filterInstance = await api.getColumnFilterInstance(item.colId);
                    await onFirstDataRendered();
                    if (filterInstance) {
                        if (item.colId.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                            filterInstance.sourceParams.gridApiRef.current?.setInputModel(item.name);
                        } else {
                            filterInstance.sourceParams.gridApiRef.current?.onSelectedOptionChanged(item.name);
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const savedSortFilterState = JSON.parse(localStorage.getItem(`filterSort-${currentTab}`)) || null;
            if (savedSortFilterState && api) {
                setFilterSortModel(savedSortFilterState);
                const toSortItem = { colId: savedSortFilterState.colId, sort: savedSortFilterState.direction, sortIndex: 0 };
                const getColumnState = api.getColumnState().map(item => ({ ...item, sort: item.colId === toSortItem.colId ? toSortItem.sort : item.sort }));

                api.applyColumnState({
                    state: getColumnState,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [api, currentTab, gridApiRef, onFirstDataRendered]);

    const isSortFilterChipShown = !!filterSortModel;
    const showFilterSection = Array.isArray(filterModel) && filterModel.length >= 1;

    const isShownFilterSection = isSortFilterChipShown || showFilterSection;

    return (
        <div className={`max-w-[1320px] flex gap-2 pb-4 h-fit flex-wrap ${isShownFilterSection ? 'block' : 'hidden'}`}>
            {
                isSortFilterChipShown && (
                    <CustomChip className='!bg-secondary-95' chipItem={filterSortModel} deleteFunction={() => resetFilterSortModel(filterSortModel.colId)} />
                )
            }
            {filterModel.map((item, index) => {
                return (
                    <CustomChip className='!bg-primary-fixed-dim' chipItem={{ ...item, key: index }} deleteFunction={() => deleteFilterItem(item)} />
                )
            })}
        </div>
    );
};

export default CustomFilterSection;
