import { Component } from "react";
import { withRouter } from "react-router-dom";
import { List } from "@/Components/DataDisplay";
import { Dialog } from "@/Components/Feedback";
import { Button, Checkbox, Select, SelectOption } from "@/Components/Inputs";
import { BarcodeScannerIcon } from "@/Icons";
import { withStore } from "@/Helpers/withStore";
import { requestSerialNumbers } from "@/Store/Actions";
import { createBatch } from "@/Store/Services";
import { BatchType } from "@/Helpers/constants";

class ClaimItemsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serialNumbers: [],
            selectedSerialNumbers: [],
            isSerialNumbersLoading: false,
            isSubmitting: false,
            gridApi: props.gridApi,
            rowData: []
        };
    };

    handleClose = () => {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    };

    handleSerialNumberChange = event => {
        const target = event?.target;
        const value = target?.value;

        this.setState({
            selectedSerialNumbers: value
        });
    };

    handleSerialNumberRemove = serialNumberToRemove => {
        if (serialNumberToRemove) {
            const selectedSerialNumbers = this.state.selectedSerialNumbers
                .filter(selectedSerialNumber => selectedSerialNumber !== serialNumberToRemove);
            this.setState({
                selectedSerialNumbers
            });
        }
    };

    handleClaimItemsClick = async () => {
        try {
            this.setState({
                isSubmitting: true
            });

            const requestBody = {
                itemID: this.state.rowData?.batchType === BatchType.BATCH ? this.state.rowData?.itemID : this.state.serialNumbers[0]?.itemID,
                orderItems: this.props.serialNumbers
                    ?.filter(serialNumber => this.state.selectedSerialNumbers.includes(serialNumber?.serial))
                    ?.map(serialNumber => serialNumber.id),
                userID: this.props.userID
            };

            if (this.state.rowData?.batchType === BatchType.BATCH) {
                requestBody.batchType = this.state.rowData?.batchType;
            }

            const createdBatch = await createBatch(() => { }, requestBody);

            if (createdBatch) {
                this.setState({
                    selectedSerialNumbers: []
                });

                if (typeof this.props.onClaim === 'function') {
                    this.props.onClaim();
                }

                this.props.history?.push(`/${this.state.rowData?.batchType}-details`, {
                    createdBatch
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isSubmitting: false
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps?.createdBatch !== this.props.createdBatch) {
            this.props.history?.push('/batch-details', {
                createdBatch: this.props.createdBatch
            });
        }

        if (prevProps?.serialNumbers !== this.props.serialNumbers) {
            this.setState({
                serialNumbers: this.props.serialNumbers
            });
        }

        if (this.props.isOpen === false) {
            this.setState({
                serialNumbers: []
            });
        }
    };

    async componentDidMount() {

        try {
            this.setState({
                isSerialNumbersLoading: true
            });

            if (this.props.gridApi) {
                const rowIndex = this.state.gridApi.getFocusedCell().rowIndex;
                const rowData = this.state.gridApi.getDisplayedRowAtIndex(rowIndex).data;
                this.setState({ rowData: rowData });

                await this.props.requestSerialNumbers({
                    itemID: rowData?.batchType === BatchType.BATCH ? rowData?.itemID : rowData?.id,
                    status: rowData?.status,
                    modUserID: this.props.userID
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isSerialNumbersLoading: false
            });
        }
    };

    render() {
        const {
            isOpen
        } = this.props || {};

        const {
            serialNumbers,
            selectedSerialNumbers,
            isSerialNumbersLoading,
            isSubmitting,
            rowData
        } = this.state || {};

        const isClaimItemButtonDisable = Array.isArray(selectedSerialNumbers) && selectedSerialNumbers.length === 0;

        return (
            <Dialog
                isOpen={isOpen || false}
                onClose={this.handleClose}
            >
                <Dialog.Title>
                    Claim Items
                </Dialog.Title>
                <Dialog.Content>
                    <p className="text-base text-on-surface-variant font-medium leading-6 tracking-[0.15px] mb-[5px]">
                        {rowData?.product || ''} {rowData?.commonname || ''}
                    </p>
                    <p className=" text-xs font-medium text-on-surface-variant leading-5 tracking-[0.1px] mb-6">
                        {rowData?.company}
                    </p>
                    <p className="text-xs text-on-surface leading-4 tracking-[0.4px] mb-3">
                        Click below to start typing or scanning serial numbers.
                    </p>
                    <Select
                        className="mb-3 claim-select"
                        startIcon={<BarcodeScannerIcon />}
                        menuHelperText={(
                            <p className="text-xs font-medium text-[#3D5567] leading-4 tracking-[0.5px] py-3 px-6">
                                Serial Number
                            </p>
                        )}
                        value={selectedSerialNumbers}
                        onChange={this.handleSerialNumberChange}
                        multiselect
                        searchable
                        isLoading={this.state.isSerialNumbersLoading}
                    >
                        {(!isSerialNumbersLoading && Array.isArray(serialNumbers)) && serialNumbers.map(({ serial }) => {
                            return (
                                <SelectOption key={serial} value={serial} className="font-normal text-sm leading-5 tracking-[0.25px] !px-6">
                                    <Checkbox checked={selectedSerialNumbers.includes(serial)} label={serial} />
                                </SelectOption>
                            );
                        })}
                    </Select>
                    <List
                        className="h-[450px] overflow-y-auto claim-select"
                        items={selectedSerialNumbers}
                        onItemRemove={this.handleSerialNumberRemove}
                    />
                </Dialog.Content>
                <Dialog.Actions className="justify-between">
                    <Button variant="flat" onClick={this.handleClose} disabled={isSubmitting}>Cancel</Button>
                    <Button disabled={isClaimItemButtonDisable} onClick={this.handleClaimItemsClick} isLoading={isSubmitting}>Claim Items</Button>
                </Dialog.Actions>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ user, orders }) => {
    return {
        userID: user?.user?.userID,
        serialNumbers: orders.serialNumbers,
        createdBatch: orders.createdBatch
    };
};

const mapDispatchToProps = ({ orders }) => {
    return {
        requestSerialNumbers: queryParams => orders.dispatch(requestSerialNumbers(queryParams)),
    };
};

const ClaimItemDialogsWithStore = withStore(
    mapStateToProps,
    mapDispatchToProps
)(ClaimItemsDialog);
const ClaimItemDialogsWithRouter = withRouter(ClaimItemDialogsWithStore);

export { ClaimItemDialogsWithRouter as ClaimItemsDialog };
