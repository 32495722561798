import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TabContainerButton } from "@/Components/Navigation";
import { RefreshIcon, ChangeCircleIcon } from "@/Icons";
import { AgGridIcon, Table, Tag, Toast } from "@/Components/Display";
import {
    CustomColumnHeader,
    ReorderColumnsPopover,
    CustomNoRowsOverlay,
    CustomLoadingOverlay
} from "@/Components/Display/Table/Custom/Components";
import { Button } from "@/Components/Inputs";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { withStore } from "@/Helpers/withStore";
import { requestOrders } from "@/Store/Actions";
import { AddKitsDialog } from "./Dialog/AddKitDialog";
import { OrderItemStatus } from "@/Helpers/constants";
import { ClaimItemsDialog } from "../OpenItems/Dialog/ClaimItemsDialog";
import CustomFilterSection from "@/Components/Display/Table/Custom/Components/CustomFilterSection";
import { getColumnState, setColumnState } from "@/Helpers/TableColumnHelper";

class OpenKitsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            showAddKitsDialog: false,
            isRefreshing: false,
            isGridLoading: true,
            isClaimItemsDialogOpen: false,
            claimItemRowData: null,
            isNotPermittedToClaimToastOpen: false,
            columnDefs: props.openKitsColumnDefs
        };
    };

    gridOptions = () => {
        return {
            groupDisplayType: 'custom',
            onFilterChanged: (params) => this.handleShowNoOverlay(params),
            getRowHeight: params => params.node.group ? 48 : 40,
            onGridReady: ({ api }) => {
                this.props.handleGridApiSetState(api);
            },
            onColumnMoved: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnVisible: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnResized: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            icons: {
                groupExpanded: () => AgGridIcon('ArrowDropUpIcon', { className: 'text-primary !size-6' }),
                groupContracted: () => AgGridIcon('DropDownIcon', { className: 'text-primary !size-6' }),
            },
            popupParent: document.querySelector('body'),
            postProcessPopup: params => {
                // check callback is for columnFilter
                if (params.type !== 'columnFilter') {
                    return;
                }

                // Calculate the position based on the column header width
                const eventSource = params.eventSource;
                const ePopup = params.ePopup;
                const popupWidth = ePopup.offsetWidth;
                const headerRect = eventSource.getBoundingClientRect();
                const popupLeft = headerRect.right - popupWidth + window.scrollX;

                if (popupLeft > 0) {
                    // Consider header padding
                    ePopup.style.left = `${popupLeft + 12}px`;
                }

                ePopup.style.width = `220px`;
                if (params?.column?.userProvidedColDef?.headerName === 'Date') {
                    ePopup.style.maxWidth = '360px';
                    ePopup.style.minWidth = '360px';
                }
            },
            suppressLoadingOverlay: true,
            getRowStyle: params => {
                if (params?.node?.group) {
                    return {
                        background: '#E2F3FF',
                        borderTop: '1px solid #77D1FF',
                        borderBottom: '0px'
                    }
                }
                if (params?.node?.childIndex % 2 === 0) {
                    return { background: '#ffffff', border: '0px' };
                }
                return { background: '#EFF4F9', border: '0px' }
            }
        }
    };

    getDefaultColDef = () => {
        return {
            rowHeight: 50,
            flex: 1,
            filter: true,
            suppressHeaderMenuButton: true,
            menuTabs: [],
            minWidth: 220,
            suppressMovable: true,
            headerComponent: CustomColumnHeader,
            headerComponentParams: {
                gridRef: this.props.openKitsGridRef
            },
            resizable: false,
            cellStyle: params => {
                if (!params?.node?.group) {
                    return { 'border': '1px solid #EAEEF3' };
                }
                return null;
            }
        };
    };

    formatToTags = (props) => {
        return <Tag className={props.getValue()} label={props.getValue()} />;
    };

    handleRefreshClick = async () => {
        if (this.props.openKitsGridApi) {
            this.setState({
                isRefreshing: true,
                isGridLoading: true
            }, async () => {
                await this.fetchKitOrders();
                this.setState({
                    isRefreshing: false,
                    isGridLoading: false
                });
            });
        }
    };

    handleOpenAddKitsDialog = () => {
        this.setState({ showAddKitsDialog: true });
    };

    handleCloseAddKitsDialog = () => {
        this.setState({ showAddKitsDialog: false });
    };

    handleRowDoubleClicked = ({ node, data }) => {
        const isStatusReady = [
            OrderItemStatus.READY_FOR_STAGING,
            OrderItemStatus.READY_FOR_QA,
            OrderItemStatus.READY_FOR_AUDIT
        ].includes(data?.status);
        const isUserIdSameWithRowData = data?.modUserID === this.props.userID;

        if (!node?.group && isStatusReady) {
            if (isUserIdSameWithRowData) {
                this.setState({
                    isNotPermittedToClaimToastOpen: true
                });
                return;
            };
            this.handleOpenClaimItemsDialog(data);
        };
    };

    handleOpenClaimItemsDialog = () => {
        this.setState({
            isClaimItemsDialogOpen: true
        });
    };

    handleCloseClaimItemsDialog = () => {
        this.setState({
            isClaimItemsDialogOpen: false
        });
    };

    handleCloseNotPermittedToClaimToast = () => {
        this.setState({
            isNotPermittedToClaimToastOpen: false
        });
    };

    renderClaimItemsDialog = () => {
        return (
            <ClaimItemsDialog
                isOpen={this.state.isClaimItemsDialogOpen}
                rowData={this.state.claimItemRowData}
                gridApi={this.props.openKitsGridApi}
                onClose={this.handleCloseClaimItemsDialog}
                onClaim={this.handleCloseClaimItemsDialog}
            />
        );
    };

    renderNotPermittedToClaimToast = () => {
        return (
            <Toast
                message="You are not permitted to claim items you have previously tested."
                status="error"
                open={this.state.isNotPermittedToClaimToastOpen}
                onClose={this.handleCloseNotPermittedToClaimToast}
            />
        );
    };

    async componentDidMount() {
        const getState = getColumnState(this.props.currentTab, this.state.columnDefs);

        if (getState) {
            this.setState({ columnDefs: getState });
        }

        this.fetchKitOrders();
    };

    fetchKitOrders = async () => {
        try {
            await this.props.requestOrders('kit');
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isGridLoading: false
            });
        };
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.orders !== this.props.orders) {
            this.setState({
                rowData: this.props.orders
            });
        }

        if (this.props.openKitsGridApi) {
            if (this.state.isGridLoading) {
                this.props.openKitsGridApi?.showLoadingOverlay();
            } else {
                this.props.openKitsGridApi?.hideOverlay();
            }
        }
    };

    handleShowNoOverlay = params => params.api.getDisplayedRowCount() === 0 ? this.props.openKitsGridApi.showNoRowsOverlay() : this.props.openKitsGridApi.hideOverlay();

    renderResetFilterButton = () => {
        const filterRef = this.props.openKitsFilterGridApiRef.current;
        const openKitsGridApi = this.props.openKitsGridApi;
        const filterDateRef = this.props.openKitsFilterDateGridApiRef.current;
        const resetSortModel = this.props.sortModelResetRef.current;
        const reOrderResetRef = this.props.reOrderResetRef.current;

        const handleResetFilters = () => {
            if (filterRef && openKitsGridApi && filterDateRef && resetSortModel && reOrderResetRef) {
                openKitsGridApi.setFilterModel(null);
                openKitsGridApi.resetColumnState();

                const colIds = openKitsGridApi.getColumnDefs().map(item => (item.colId));

                colIds?.forEach((colId) => {
                    filterRef.getFilterInstance(colId, (filterInstance) => {
                        if (filterInstance) {
                            const currentFunctions = filterInstance.sourceParams.gridApiRef?.current;
                            if (currentFunctions) {
                                currentFunctions.resetSelectedOptions();
                            }
                        }
                    });
                });
                filterDateRef.getFilterInstance('createDate', (filterInstance) => {
                    if (filterInstance) {
                        const currentFunctions = filterInstance.sourceParams.gridApiRef?.current;
                        if (currentFunctions) {
                            currentFunctions.resetDateSelected();
                        }
                    }
                });
                resetSortModel.resetFilterModel();
                reOrderResetRef.resetReOrderModel();
            }
        };

        return (
            <Button
                variant="flat"
                className="w-[152px]"
                startIcon={<ChangeCircleIcon className="text-on-surface-variant" />}
                onClick={handleResetFilters}
            >
                Reset Filters
            </Button>
        );
    };

    render() {
        const {
            showAddKitsDialog,
            isRefreshing,
            rowData,
            isGridLoading,
            isClaimItemsDialogOpen,
            isNotPermittedToClaimToastOpen,
            columnDefs
        } = this.state;

        const {
            openKitsGridRef,
            openKitsGridApi,
            openKitsColumnDefs,
            currentTab,
            openKitsFilterGridApiRef,
            sortModelResetRef,
            reOrderResetRef
        } = this.props;

        return (
            <div>
                <div className="flex justify-between py-2 pt-3 mb-2">
                    <TabContainerButton >
                        <ReorderColumnsPopover
                            gridApi={openKitsGridApi}
                            columns={openKitsColumnDefs}
                            currentTab={currentTab}
                            reOrderResetRef={reOrderResetRef}
                        />
                        {this.renderResetFilterButton()}
                    </TabContainerButton>
                    <TabContainerButton>
                        <Button onClick={this.handleOpenAddKitsDialog}>Add Kits</Button>
                        <Button
                            className="w-[122px]"
                            startIcon={<RefreshIcon />}
                            isLoading={isRefreshing}
                            onClick={this.handleRefreshClick}
                        >
                            Refresh
                        </Button>
                    </TabContainerButton>
                </div>
                <CustomFilterSection
                    api={openKitsGridApi}
                    gridApiRef={openKitsFilterGridApiRef}
                    columns={openKitsColumnDefs}
                    sortModelResetRef={sortModelResetRef}
                    currentTab={currentTab}
                />
                <Table
                    className="ag-theme-quartz open-kits-table"
                    reactiveCustomComponents
                    ref={openKitsGridRef}
                    rowData={rowData}
                    groupDefaultExpanded={1}
                    columnDefs={columnDefs}
                    defaultColDef={this.getDefaultColDef()}
                    suppressMenuHide
                    columnMenu="new"
                    enableRowGroup
                    rowGroupPanelSuppressSort
                    gridOptions={this.gridOptions()}
                    suppressLoadingOverlay={false}
                    noRowsOverlayComponent={CustomNoRowsOverlay}
                    loadingOverlayComponent={CustomLoadingOverlay}
                    suppressNoRowsOverlay={isGridLoading}
                    suppressContextMenu
                    rowSelection="single"
                    onRowDoubleClicked={this.handleRowDoubleClicked}
                />
                {showAddKitsDialog && (
                    <AddKitsDialog
                        showAddKitsDialog={showAddKitsDialog}
                        handleBoxSerialKeyDown={this.handleBoxSerialKeyDown}
                        handleCloseAddKitsDialog={this.handleCloseAddKitsDialog}
                    />
                )}
                {isClaimItemsDialogOpen && this.renderClaimItemsDialog()}
                {isNotPermittedToClaimToastOpen && this.renderNotPermittedToClaimToast()}
            </div>
        );
    };
};

const mapStateToProps = ({ orders, user }) => {
    return {
        orders: orders?.orders,
        openKitsColumnItems: orders?.openKitsColumnItems,
        userID: user?.user?.userID,
    };
};

const mapDispatchToProps = ({ orders }) => {
    return {
        requestOrders: batchType => orders.dispatch(requestOrders(batchType))
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OpenKitsPage));
