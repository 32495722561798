import React, { useEffect, useState, useRef, cloneElement, isValidElement, useMemo, forwardRef, Children } from 'react';
import { createPortal } from "react-dom";

import { withHandleClickOutside } from "@/HOC";
import { ArrowDropDownIcon, MenuSearchIcon, RefreshSpinnerIcon } from "@/Icons";

const SELECT_OPTION_HEIGHT = 46;

const Select = forwardRef(({
	'item-count': itemCount,
	status,
	label,
	size,
	value,
	startIcon,
	endIcon,
	multiselect,
	placeholder,
	children,
	className,
	searchable,
	helperText,
	error,
	badgeVariant,
	onCloseOptions,
	disabled,
	variant,
	menuHelperText,
	isLoading,
	menuStyle: menuCustomStyle,
	portalContainer,
	...props
}, ref) => {
	//HOC properties
	const { isOpen, setIsOpen, refNode, onChange, portalRef, ...restOfProperties } = props;
	const [newChildren, setNewChildren] = useState(children);
	const [keyWord, setKeyWord] = useState('');
	const [cursor, setCursor] = useState(0);
	const [height, setHeight] = useState(SELECT_OPTION_HEIGHT);
	const searchRef = useRef();
	const parentRef = useRef();
	const [menuHelperTextRef, setMenuHelperTextRef] = useState();

	const parentReference = useRef(null);
	const [top, setTop] = useState(null);
	const [menuStyle, setMenuStyle] = useState({ left: 0 });
	const [width, setWidth] = useState(null);

	let childrenWithProps = [];

	const getSize = () => {
		if (size === 'small') {
			return 'select-small'
		} else if (size === 'medium') {
			return 'select-medium'
		} else if (size === 'large') {
			return 'select-large'
		} else if (size === 'full') {
			return 'select-full'
		} else if (size === 'xs') {
			return 'select-xs'
		}

		return 'select-normal'
	}


	const searchRefFocus = () => {
		searchRef.current.focus();
	};
	const executeScroll = () => {
		parentRef.current.scrollTo(0, height - SELECT_OPTION_HEIGHT)
	};


	if (newChildren) {
		childrenWithProps = (
			React.Children.map(newChildren, (child, index) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						multiselect,
						index: index,
						selectedValue: value,
						handleClick: onChange,
						cursor: cursor,
						searchable: searchable,
						setCursor: setCursor,
						setKeyWord: setKeyWord,
						searchRefFocus: searchRefFocus,
						setHeight: setHeight,
					});
				}
				return child;
			})
		);
	}

	const menuListHeight = useMemo(() => {
		const childrenHeight = (size !== 'xs') ? SELECT_OPTION_HEIGHT : 25;
		let height = SELECT_OPTION_HEIGHT;

		if (newChildren?.length === 0 && keyWord.length > 0) {
			return '100%';
		}

		if (isOpen && newChildren) {
			height = newChildren.length > 10 ? SELECT_OPTION_HEIGHT * 10 : (newChildren.length * childrenHeight);
			height += (menuHelperText && menuHelperTextRef ? menuHelperTextRef?.clientHeight : 0);
		}
		
		return `${height}px`;
	}, [isOpen, newChildren, menuHelperText, size, menuHelperTextRef, keyWord.length]);

	let activeValue = childrenWithProps.find(childrenWithProp => childrenWithProp.props.value === value);


	const handleSearch = (event) => {
		if (variant === 'menu') return;
		if (event.target.value && children) {
			const filtered = children.filter(child => {
				if (child && child.key) {
					return child.key.toLowerCase().includes(event.target.value.toLowerCase());
				}
				return false;
			});

			if (filtered.length > 0) {
				setNewChildren(filtered);
			} else {
				setNewChildren([]);
			}
		} else {
			setNewChildren(children);
		}
		setKeyWord(event.target.value);
		setCursor(0);
		setHeight(0);
		executeScroll();
	}

	const handleKeyDown = (e) => {
		if (cursor === 0) {
			setHeight(0);
		}

		if (!e) {
			return;
		}

		if (e.keyCode === 38 && cursor > 0) {
			setCursor(cursor - 1);
			setHeight(height - SELECT_OPTION_HEIGHT);
		} else if (e.keyCode === 40 && cursor < newChildren.length - 1) {
			setCursor(cursor + 1);
			setHeight(height + SELECT_OPTION_HEIGHT);
		}

		executeScroll();

		if (e.key?.toLowerCase()?.trim() === 'enter') {
			const enteredElement = newChildren[cursor];

			if (enteredElement) {
				let arrayOfValue = [];
				const enteredElementValue = enteredElement.props?.value;
				if (multiselect) {
					if (value.includes(enteredElementValue)) {
						arrayOfValue = [...value.filter(e => e !== enteredElementValue)];
					} else {
						arrayOfValue = [...value, enteredElementValue];
					}
				} else {
					arrayOfValue = enteredElementValue;
				}

				setHeight(0);
				setCursor(0);
				setKeyWord('');
				onChange({ target: { value: arrayOfValue } });
				parentRef.current.scrollTo(0, 0);
			}
		}
	};

	useEffect(() => {

		if (children && variant !== 'menu') {
			let newChildren = children.filter(child => child && child !== null);

			if (searchable) {
				const filtered = newChildren.filter(child => {
					if (child && child.key) {
						return child.key.toLowerCase().includes(keyWord?.toLowerCase());
					}
					return false;
				});
				setNewChildren(filtered);
			} else {
				setNewChildren(newChildren);
			}
		} else {
			setNewChildren([]);
		}
	}, [children, searchable, keyWord, variant]);

	useEffect(() => {
		if (isOpen && parentReference && parentReference.current && !multiselect) {
			const referenceNode = parentReference.current.getBoundingClientRect();
			const viewportHeight = window.innerHeight;
			const elementPosition = referenceNode.top + referenceNode.height + menuListHeight + 30;

			if (viewportHeight > elementPosition) {
				setTop(0)
			} else {
				setTop(viewportHeight - (elementPosition))
			}

		}

	}, [isOpen, multiselect, menuListHeight])

	useEffect(() => {
		if (onCloseOptions) {
			onCloseOptions(isOpen);
		}
	}, [isOpen, onCloseOptions]);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		setWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);

		return () => {
			window.removeEventListener('resize', updateDimensions);
		}
	}, []);

	useEffect(() => {
		if (isOpen && variant === 'default') {
			let indexOfCurrentSelectedOption = 0;

			for (const key in parentRef?.current?.children[0].children) {
				if (parentRef?.current?.children[0]?.children[key]?.classList?.contains('bg-grey-4')) {
					indexOfCurrentSelectedOption = key
				}
			}
			parentRef?.current?.children[0]?.children[indexOfCurrentSelectedOption]?.scrollIntoView();
		}

		if (isOpen && variant === 'menu') {
			// 1615px is the minimum screensize before the menu switch to right
			// and to prevent menu to overflow
			if (width < 1615) {
				return setMenuStyle({ right: 0 });
			}
			return setMenuStyle({ left: 0 });
		}
	}, [isOpen, variant, width]);

	const renderHelperText = () => {
		return (
			<div className={`textfield-helper ${(status !== 'default' ? status === 'validation' ? 'text-primary' : 'text-error' : '')}`}>
				{helperText}
			</div>
		);
	};

	const renderIcon = () => {
		if (endIcon) {
			return endIcon;
		}

		return <ArrowDropDownIcon className={`${disabled ? " text-outline " : "text-black"} ${isOpen ? 'rotate-180' : ''}`} />;
	};

	const renderMenuHelperText = () => {
		if (isValidElement(menuHelperText)) {
			return cloneElement(
				menuHelperText,
				{ ...(menuHelperText?.props || {}), ref: (ref) => setMenuHelperTextRef(ref) }
			);
		}
	};

	const renderSingleMenu = () => {
		const menu = (
			<div className="select-single" style={menuCustomStyle || {}}>
				<div ref={parentRef} className={`select-menu ${(size === 'xs' ? 'select-menu-xs' : '')}`} style={{ transform: `translate(0px, ${top}px)` }} onClick={(e) => setIsOpen(false)}>
					{
						searchable && (
							<div className={`textfield`} style={{ borderBottom: '1px solid #a2aeb6' }}>
								<div className={`textfield-group-search`}>
									<div className={`textfield-group-icon`}>
										<span className="flex items-center">
											<MenuSearchIcon className="text-[#121212]"></MenuSearchIcon>
										</span>
									</div>
									<input value={keyWord} className={`textfield-group-input`} placeholder="Search" autoFocus={isOpen} onChange={(event) => handleSearch(event)} onKeyDown={(event) => handleKeyDown(event)} ref={searchRef} />
								</div>
							</div>
						)
					}
					{menuHelperText || ''}

					<ul className="select-menu-list" style={{ height: menuListHeight + 'px' }}>
						{
							childrenWithProps.length > 0 ? childrenWithProps :
								<div className="no-data">No data</div>
						}
					</ul>
				</div>
			</div>
		);

		if (portalContainer) {
			return createPortal((
				<>
					{Children?.map(menu, child => {
						if (isValidElement(child)) {
							return cloneElement(child, { ref: portalRef });
						}
						return child;
					})}
				</>
			), portalContainer || document.body);
		}

		return menu;
	};

	return (
		<div ref={refNode} className={`select ${getSize()} ${className || ''}`}>
			{label && <div className="select-label">{label}</div>}

			<button ref={ref} onClick={(event) => { event.stopPropagation(); setIsOpen(!isOpen); setCursor(0) }} className={`select-button outline-none`} disabled={disabled} {...restOfProperties}>
				<div className="select-button-content">
					<div className="flex items-center">
						{startIcon && startIcon}
						<span className={`select-button-label ${(startIcon ? 'ml-3' : '')} ${placeholder && !activeValue ? 'select-button-label-placeholder' : ''}`}>
							{
								activeValue && !multiselect &&
								activeValue.props.children
							}
							{
								multiselect &&
								placeholder
							}
							{
								!multiselect && !activeValue &&
								placeholder
							}
						</span>
					</div>
					<div className="flex items-center justify-between">
						{/* {multiselect && value.length > 0 && <BfBadge variant={badgeVariant ? badgeVariant : ''} text={value.length.toString()} />} */}
						{/* {itemCount && children.length > 0 && <BfBadge color="bg-primary" text={children.length.toString()} />} */}
						{renderIcon()}
					</div>
				</div>
			</button>
			{
				isOpen && variant === 'menu' &&
				(
					<div className="select-menu select-variant-menu" style={{ transform: `translate(0px, ${top}px)`, width: 'max-content', ...menuStyle }}>
						{children}
					</div>
				)
			}
			{
				isOpen && variant !== 'menu' && !multiselect && renderSingleMenu()
			}
			{
				isOpen && variant !== 'menu' && multiselect &&
				(
					<div className={`multiselect`}>
						<div ref={parentRef} className={`multiselect-menu ${searchable ? '' : 'rounded-t-md'}`}>
							{
								searchable && (
									<div className={`textfield`} style={{ borderBottom: '1px solid #a2aeb6' }}>
										<div className={`textfield-group-search`}>
											<div className={`textfield-group-icon`}>
												<span className="flex items-center">
													<MenuSearchIcon className="text-[#121212]" />
												</span>
											</div>
											<input value={keyWord} className={`textfield-group-input`} placeholder="Search" autoFocus={isOpen} onChange={(event) => handleSearch(event)} onKeyDown={(event) => handleKeyDown(event)} ref={searchRef} />
										</div>
									</div>
								)
							}
							{menuHelperText ? renderMenuHelperText() : ''}
							{isLoading && <RefreshSpinnerIcon className="absolute animate-spin top-[12px] right-[12px]" />}
							<ul className="select-menu-list" style={{ height: menuListHeight }}>
								{
									childrenWithProps.length > 0 ? childrenWithProps :
										<div className="no-data">No data</div>
								}
							</ul>
						</div>
					</div>
				)
			}
			{(helperText && renderHelperText())}

		</div>
	);
});

export default withHandleClickOutside(Select);
