import { BarcodeScannerIcon } from "@/Icons";
import React, { createRef, useEffect, useRef } from "react";
import { Dialog } from '@/Components/Feedback';
import { Button, Checkbox, Select, SelectOption, TextField } from "@/Components/Inputs";
import { DataTable } from "@/Components/DataDisplay";
import { useState } from "react";
import { useSelector, useDispatch } from "@/Store/StoreContextProvider";
import { receiveOptionPartNumber, requestCompanies, requestOptionPartNumber } from "@/Store/Actions";
import { checkDuplicateSerials, createBatch, createBatchAndClaim } from "@/Store/Services";
import { ErrorIcon } from "@/Icons";
import { useHistory } from "react-router-dom";
import { Toast } from "@/Components/Display";

export const AddItemsDialog = ({
    showAddItemsDialog,
    handleCloseAdddItemsDialog,
}) => {
    const [companyOnChange, setHandleCompanyChange] = useState('');
    const [fieldRefs, setFieldRefs] = useState([]);
    const [partNumberOnChange, setPartNumberOnChange] = useState('');
    const [orderNumberOnChange, setOrderNumberOnChange] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [addedItems, setAddedItems] = useState([]);
    const [isError, setIsError] = useState(false);
    const [handleOpenToast, setHandleOpenToast] = useState(false);
    const [currentSerialBoxFocus, setCurrentSerialBoxFocus] = useState(null);
    
    const orderNumberFieldRef = useRef();
    const history = useHistory();

    const companies = useSelector(({ companies }) => companies?.companies);
    const partNumber = useSelector(({ orders }) => orders?.partNumber);
    const user = useSelector(({ user }) => user.user);

    const dispatch = useDispatch((dispatch) => dispatch.orders);
    const companyDispatch = useDispatch(dispatch => dispatch?.companies);

    const hasAddedItems = addedItems?.length > 0;
    const hasCompanySelected = !!companyOnChange;
    const hasPartNumberSelected = !!partNumberOnChange;

    const isValueIsEmpty = addedItems.some((curentItem) => curentItem.serial === "" || curentItem.boxSerial === "");
    const isSameValue = addedItems.every((curentItem) => curentItem.serial === curentItem.boxSerial);

    const handleRedirect = (createdBatch) => {
        history?.push('/batch-details', {
            createdBatch,
            itemID: createdBatch?.testItem?.itemID
        });
    };

    const handleCompanyOnChange = event => {
        const target = event?.target;
        const value = target?.value ?? '';
        setHandleCompanyChange(value);
        dispatch(requestOptionPartNumber(value));
    };

    const handlePartNumberOnChange = event => {
        const target = event?.target;
        const value = target?.value ?? '';
        setPartNumberOnChange(value);
    };

    const handleOrderNumberChange = event => {
        const target = event?.target;
        const value = target?.value ?? '';
        setOrderNumberOnChange(value);
    };

    const handleItemsOnChange = (event, field) => {
        const value = event.target.value;
        const id = parseInt(event.target.name);

        setAddedItems(prevData =>
            prevData.map(item => {
                let hasSerialInputAlready = item?.hasSerialInputAlready ?? false;
                let hasBoxSerialInputAlready = item?.hasBoxSerialInputAlready ?? false;

                if (field === 'serial') { 
                    hasSerialInputAlready = true;
                }

                if (field === 'boxSerial') {
                    hasBoxSerialInputAlready = true;
                }

                return item.id === id ? { 
                    ...item, 
                    [event.target.dataset.field]: value, 
                    hasSerialInputAlready, 
                    hasBoxSerialInputAlready, 
                    onFocus:true 
                } : item
            })
        );
        setCurrentSerialBoxFocus(id);
    };

    const handleAddItemsCheckboxChange = event => {
        const target = event?.target;
        const id = event.target.name;
        const checked = target?.checked ?? false;
        setAddedItems(prevData =>
            prevData.map(item =>
                item.id === parseInt(id) ? { ...item, checked: checked } : item
            )
        );
    };

    const handleRemoveItem = () => {
        setAddedItems(addedItems.filter((item) => item.checked !== true));
    };

    const handleAddItems = () => {
        if (isError) return
        const newId = Math.floor(Math.random() * 9000) + 1000;
        const newItem = {
            checked: false,
            id: parseInt(newId),
            orderNumber: orderNumberOnChange,
            serial: "",
            boxSerial: "",
            isError: "",
            onFocus: false,
            hasSerialInputAlready: false,
            hasBoxSerialInputAlready: false
        };
        setAddedItems(prevAddItemRowData => [...prevAddItemRowData, newItem]);
    };

    const handleAddSaveAndClaim = async () => {
        setIsLoading(true);
        try {
            const payload = {
                companyID: companyOnChange,
                itemID: partNumberOnChange,
                userID: user?.userID,
                data: addedItems.map((item) => {
                    return {
                        id: 0,
                        orderitemID: 0,
                        kitPrimaryItemID: 0,
                        serial: item.serial,
                        boxserial: item.boxSerial,
                        itemID: 0,
                        companyID: 0,
                        checklistTemplate: "",
                        optionTemplate: "",
                        creatorID: 0,
                        createDate: null,
                        modDate: null,
                        modUserID: 0,
                        modUserName: "",
                        status: "",
                        company: "",
                        accountNumber: "",
                        productID: 0,
                        product: "",
                        commonname: "",
                        fullname: "",
                        checklist: "",
                        files: "",
                        tags: "",
                        options: "",
                        total: 0,
                        qc: "",
                        kitID: 0,
                        kitName: "",
                        staging: 0,
                        randomAudit: 0,
                        raPercentage: 0,
                        orderNumber: item.orderNumber,
                        batchID: 0,
                        auditDate: null,
                        auditUser: 0
                    }
                })
            };

            const createdBatchAndClaim = await createBatchAndClaim(() => { }, payload);
            if (createBatchAndClaim) {
                const initBatchPayload = {
                    itemID: partNumberOnChange,
                    orderItems: createdBatchAndClaim?.added,
                    userID: user?.userID
                };
              
                const createdBatch = await createBatch(() => { }, initBatchPayload);

                if (createdBatch) {
                    handleCloseAdddItemsDialog();
                    handleRedirect(createdBatch);
                    dispatch(receiveOptionPartNumber([]));
                };
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        };
    };

    const onBlurChange = async (event, field) => {
        const id = event.target?.name;
        const value = event.target?.value || '';

        setAddedItems(prevData =>
            prevData.map(item =>
                item.id === parseInt(id) ? { ...item, onFocus: false } : item
            )
        );

        if (field === 'serial' && value) {
            const isDuplicate = await checkDuplicateSerials(() => { }, {
                serial: value,
                itemID: partNumberOnChange
            });

            if (isDuplicate) {
                setErrorMessage(`Serial number ${value} already exists.`);
            }
        }
     };

    useEffect(() => {
        (async () => {
            if (!companies) {
                await companyDispatch(requestCompanies());
            } 
        })();
    }, [companies, companyDispatch]);

    useEffect(() => {
        if (addedItems.length === 0) {
            setIsError(false);
        };

        const currentFocusedItem = addedItems.find(item => item.id === currentSerialBoxFocus);
        if (currentFocusedItem?.onFocus) {
            setErrorMessage('');
            return;
        }

        const isASerialFieldEmpty = addedItems.some(addedItem =>
            addedItem?.hasSerialInputAlready && 
            addedItem?.serial?.length === 0
        );
        
        const isABoxSerialFieldEmpty = addedItems.some(addedItem => 
            addedItem?.hasBoxSerialInputAlready && 
            addedItem?.boxSerial?.length === 0
        );
        
        const isThereDifferentSerialAndBoxSerial = addedItems.some(addedItem =>
             addedItem?.hasSerialInputAlready && 
             addedItem?.hasBoxSerialInputAlready && 
             addedItem?.serial !== addedItem.boxSerial
        );

        if (isASerialFieldEmpty || isABoxSerialFieldEmpty) {
            setErrorMessage('One or more required fields are empty.');
            return;
        }

        if (isThereDifferentSerialAndBoxSerial) {
            setErrorMessage('Box Serial does not match item Serial Number.');
        }
    }, [
        addedItems,
        currentSerialBoxFocus
    ]);

    useEffect(() => {
        fieldRefs?.[addedItems?.length - 1]?.orderNumberRef?.current?.focus();
    }, [fieldRefs, addedItems]);

    useEffect(() => {
        setIsError(errorMessage.length > 0);
    }, [errorMessage]);

    const getOneItems = addedItems[0]?.orderNumber;

    const itemshaveChecked = addedItems.some(items => items.checked);

    return (
        <Dialog
            className="h-[829px] pt-8 px-8 pb-[24px] add-items-dialog"
            isOpen={showAddItemsDialog}
            onClose={handleCloseAdddItemsDialog}
        >
            <Dialog.Title className=" text-2xl mb-[16px] font-roboto font-medium leading-8 w-[640px]">
                Add Items
            </Dialog.Title>
            <Dialog.Content className="space-y-4 pb-4 border-b-[1px] border-[#EAEEF3] ">
                <Toast 
                    autoHide={true} 
                    onClose={() => setHandleOpenToast(false)} 
                    open={handleOpenToast} 
                    message="Order number has changed. Items have been cleared." 
                />
                <Select
                    label="Company"
                    value={companyOnChange}
                    onChange={handleCompanyOnChange}
                    className="add-items-select"
                    menuHelperText={(<p className="w-full p-3 text-xs font-medium leading-4 tracking-[0.5px] font-roboto text-on-surface-variant-2">Select Company</p>)}
                    searchable
                >
                    {companies?.map(company => {
                        return (
                            <SelectOption
                                key={company.name}
                                value={company.id}
                                className="font-normal text-[14px] leading-[20px] tracking-[0.25px] !px-[24px]"
                            >
                                {company.name}
                            </SelectOption>
                        );
                    })}
                </Select>
                <Select
                    label="Part Number"
                    value={partNumberOnChange}
                    onChange={handlePartNumberOnChange}
                    className="add-items-select"
                    searchable
                    disabled={!hasCompanySelected}
                    menuHelperText={(<p className="w-full p-3 text-xs font-medium leading-4 tracking-[0.5px] font-roboto text-on-surface-variant-2">Select Part Number</p>)}
                >
                    {partNumber?.map(partNumber => {
                        return (
                            <SelectOption 
                                key={partNumber.partNumber} 
                                value={partNumber.id} 
                                className="font-normal text-[14px] leading-[20px] tracking-[0.25px] !px-[24px]"
                            >
                                {partNumber.partNumber}
                            </SelectOption>
                        );
                    })}
                </Select>
                <TextField
                    ref={orderNumberFieldRef}
                    disabled={!hasPartNumberSelected}
                    onChange={handleOrderNumberChange}
                    onBlur={params => {
                        if (params.type === "blur") {
                            if (getOneItems) {
                                if (orderNumberOnChange !== getOneItems) {
                                    setHandleOpenToast(true);
                                    setAddedItems([]);
                                };
                            };
                        };
                    }}
                    autoFocus
                    label="Order Number"
                    startIcon={<BarcodeScannerIcon className="svg-small" />}
                    className="h-10 ordernumber-textfield"
                />
                <div className="relative">
                    {isError && <div className="absolute z-50 bottom-[23px] left-1/2 -translate-x-1/2 min-w-[386px] w-fit py-[12px] flex gap-[8.28px] font-open-sans rounded-[29px] items-center justify-center  text-center  text-[14px] font-semibold bg-on-surface-variant text-white">
                        <ErrorIcon className="text-error bg-white rounded-full" />
                        <p className="min-w-[280px]">
                            {errorMessage}
                        </p>
                    </div>}
                    <div className="div">
                        <div className={` ${orderNumberOnChange.length !== 0 ? "hidden" : ""}  
                            opacity-[0.4] rounded-[5px] absolute z-50 bg-white w-full h-full`} />
                        <DataTable
                            className="add-items-datatable"
                            bodyClassName="!overflow-x-hidden"
                            rows={addedItems}
                            rowRenderer={(rowData, rowIndex) => {
                                return [
                                    <div className="flex items-center relative justify-center  border-[0.8px] border-surface-container h-[48px] w-full">
                                        <Checkbox 
                                            name={rowData?.id} 
                                            checked={rowData?.checked} 
                                            onChange={handleAddItemsCheckboxChange} 
                                            value={rowData?.id}
                                         />
                                    </div>,
                                    <div className="add-items-textfield">
                                        <TextField
                                            className="add-item-textfield-select"
                                            value={rowData?.serial}
                                            name={rowData?.id}
                                            data-field="serial"
                                            onBlur={event => onBlurChange(event, 'serial')}
                                            onChange={event => handleItemsOnChange(event, 'serial')}
                                        />
                                    </div>,
                                    <div className={`add-items-textfield ${rowData.isError ? "border-error border-[.8px] hover:border-error " : " "}`}>
                                        <TextField
                                            className="add-item-textfield-select"
                                            value={rowData?.boxSerial}
                                            name={rowData?.id}
                                            data-field="boxSerial"
                                            onBlur={onBlurChange}
                                            onChange={event => handleItemsOnChange(event, 'boxSerial')}
                                        />
                                    </div>,
                                    <div className={`add-items-textfield `}>
                                        <TextField
                                            onPaste={(e) => {
                                                setTimeout(() => {
                                                    setFieldRefs((prevFieldRefs) => ([
                                                        ...prevFieldRefs,
                                                        {
                                                            orderNumberRef: createRef(),
                                                            serialNumberRef: createRef(),
                                                            boxRef: createRef(),
                                                        }
                                                    ]));
                                                }, 10);
                                            }}
                                            ref={fieldRefs?.[rowIndex]?.boxRef}
                                            className="add-item-textfield-select "
                                            onKeyDown={(e) => {
                                                if (e?.key?.toLowerCase().trim() === 'tab') {
                                                    handleAddItems();
                                                }
                                            }}
                                            name={rowData?.id}
                                            data-field="orderNumber"
                                            value={rowData?.orderNumber}
                                            onChange={handleItemsOnChange}

                                        />
                                    </div>,
                                ]
                            }}
                            columns={[
                                {
                                    field: '',
                                    label: '',
                                    width: 48,
                                    style: { minWidth: 'unset' },
                                    sortable: false
                                },
                                {
                                    field: '',
                                    label: <p className="flex items-center gap-[10px]"><BarcodeScannerIcon />Serial Number</p>,
                                    width: 198,
                                    fluid: true,
                                    style: { minWidth: 'unset' },
                                    sortable: false
                                },
                                {
                                    field: '',
                                    label: <p className="flex items-center gap-[10px]"><BarcodeScannerIcon />Box Serial</p>,
                                    fluid: true,
                                    width: 198,
                                    style: { minWidth: 'unset' },
                                    sortable: false
                                },
                                {
                                    field: '',
                                    label: <p className="flex items-center gap-[10px]"><BarcodeScannerIcon />Order Number</p>,
                                    fluid: true,
                                    width: 198,
                                    style: { minWidth: 'unset' },
                                    sortable: false
                                }
                            ]}
                        />
                    </div>

                </div>
                <div className="flex space-x-2">
                    <Button
                        disabled={addedItems.some(rowData => rowData.checked) || !orderNumberOnChange > 0 || isLoading}
                        onClick={() => {
                            handleAddItems();
                        }}
                    >
                        Add Item(s)
                    </Button>
                    <Button
                        className="border-none"
                        disabled={!itemshaveChecked}
                        variant={!addedItems.some(rowData => rowData.checked) ? "flat" : "default"}
                        onClick={handleRemoveItem}
                    >
                        Remove
                    </Button>
                </div>
            </Dialog.Content>
            <Dialog.Actions className="justify-between">
                <Button variant="flat" onClick={handleCloseAdddItemsDialog}>Cancel</Button>
                <Button
                    disabled={!hasAddedItems || isLoading || isError || isValueIsEmpty || !isSameValue}
                    isLoading={isLoading}
                    onClick={handleAddSaveAndClaim}
                >
                    Save & Claim
                </Button>
            </Dialog.Actions>
        </Dialog>
    );
}
