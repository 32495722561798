import { useEffect, useImperativeHandle, useState } from "react";
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    MouseSensor,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'
import { Popover, Stack } from "@/Components/Display";
import { Button, Checkbox, TextField } from "@/Components/Inputs";
import { MenuSearchIcon, ViewColumnIcon } from "@/Icons";
import { LoadingDialog } from "@/Components/Feedback";

const fixedColumns = ['company', 'company name'];
const disabledColumns = [...fixedColumns, 'createDate'];

const ColumnItems = ({ item, onChange, draggable }) => {
    const isDisabled = disabledColumns.includes(item?.columnName.toLowerCase().trim());
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: item?.id, disabled: isDisabled });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    return (
        <Checkbox
            style={style}
            ref={setNodeRef}
            attributes={attributes}
            listeners={listeners}
            key={item.id}
            label={item.columnName}
            onClick={onChange}
            name={item.colId}
            checked={item.hide}
            disabled={isDisabled}
            draggable={draggable}
            className={`
                w-full h-8 justify-start rounded cursor-move
                ${item.showRowGroup ? 'hidden' : ''}
                ${isDisabled ? 'cursor-not-allowed opacity-40' : 'hover:bg-primary-fixed'}
                ${draggable ? 'opacity-40 hover:!bg-transparent' : ''}
            `}
        />
    );
};

const ReorderColumnsPopover = ({
    gridApi,
    currentTab,
    columns,
    reOrderResetRef
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const initialColumns = columns?.map((column, idx) => {
        return {
            id: idx + 1,
            hide: true,
            columnName: column.headerName ? column.headerName : column.field,
            colId: column.colId ? column.colId : column.field
        };
    }) || [];

    const [searchValue, setSearchValue] = useState('');
    const [columnItems, setColumnItems] = useState(initialColumns);
    const [filteredColumnItems, setFilteredColumnItems] = useState(columnItems);
    const [isLoading, setIsLoading] = useState(false);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 },
        })
    );

    const handleOpenChange = () => {
        if (columns && gridApi) {
            setIsOpen(true);
        };
    };

    const handleDragEnd = (event) => {
        const { active, over } = event || {};

        if (over && active && active.id !== over.id) {
            setColumnItems((items) => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over.id);

                if (['open-items', 'open-kits'].includes(currentTab)) {
                    if (oldIndex === 0 || newIndex === 0) {
                        return items;
                    }
                }

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const handleSearchChange = event => {
        const target = event?.target;
        const value = target?.value || '';
        setSearchValue(value);
    };

    useEffect(() => {
        const updateFilteredColumns = () => {
            try {
                const columnState = JSON.parse(localStorage.getItem(currentTab));
                if (columnState) {
                    setFilteredColumnItems(columnState.map(item => ({ ...item, hide: !item.hide })));
                    setColumnItems(columnState.map(item => ({ ...item, hide: !item.hide })));
                };
            } catch (error) {
                console.log(error);
            }
        };
        updateFilteredColumns();
    }, [currentTab]);

    const handleCurrentColumnCheck = (event) => {
        const target = event?.target;
        const checked = target?.checked || false;
        setColumnItems(columnItems.map(item => {
            if (item.colId === target.name) {
                return { ...item, hide: checked };
            };
            return item;
        }));
    };

    const handleApply = () => {
        if (!gridApi) {
            return;
        }

        const column = columnItems.map(item => {
            const isCompany = item.colId?.toLowerCase() === "company";
            return {
                ...item,
                colId: item.colId,
                hide: isCompany || !item.hide
            };
        });

        setIsLoading(true);
        gridApi.applyColumnState({
            state: column,
            applyOrder: true
        });
        setTimeout(() => {
            setIsLoading(false);
            setIsOpen(false);
        }, 500);
    };

    const handleCancel = () => {
        setIsOpen(false);
        setSearchValue('');
        if (gridApi) {
            const columnCurrentState = gridApi.getColumnState();
            setColumnItems(
                columnItems.map((column) => {
                    const currentColumnState = columnCurrentState.find(
                        (item) => item.colId.toLowerCase() === column.colId.toLowerCase()
                    );
                    const isHiddenByDefault = column.colId === 'company';

                    const shouldHideColumn = currentColumnState
                        ? (isHiddenByDefault ? true : !currentColumnState.hide)
                        : column.hide;

                    return {
                        ...column,
                        hide: shouldHideColumn
                    };
                })
            );
        };
    };

    const resetReOrderModel = () => {
        setColumnItems(initialColumns);
    };

    useImperativeHandle(reOrderResetRef, () => ({
        resetReOrderModel,
    }));

    useEffect(() => {
        if (searchValue) {
            setFilteredColumnItems(columnItems?.filter(columnItem =>
                columnItem.columnName.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
            ));
            return;
        };
        setFilteredColumnItems(columnItems);
    }, [searchValue, columnItems]);

    return (
        <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
            placement="bottom-start"
            dismissOptions={{
                escapeKey: false
            }}
        >
            <LoadingDialog isOpen={isLoading} />
            <Popover.Trigger asCustomComponent>
                <Button variant="flat" startIcon={<ViewColumnIcon />} onClick={handleOpenChange}>Columns</Button>
            </Popover.Trigger>
            <Popover.Content className="p-3 w-[222px]">
                <div className="flex flex-col gap-3">
                    <p className="text-on-suface-variant-2 text-xs font-medium leading-4 tracking-[0.50px]">Columns</p>
                    <TextField
                        className="reorderColumn-textfield"
                        placeholder="Search"
                        startIcon={<MenuSearchIcon className="!size-5 text-on-surface-variant" />}
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                    <p className="text-[#A2AEB6] text-xs leading-4 tracking-[0.40px]">Drag columns to reorder</p>
                    <div className="h-[213px] mt-0 flex flex-col overflow-y-auto overflow-x-hidden gap-2 w-full mb-[7px] column-items">
                        <DndContext
                            collisionDetection={closestCenter}
                            sensors={sensors}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={filteredColumnItems.filter(column => !fixedColumns.includes(column.columnName.toLowerCase().trim()))}
                                strategy={verticalListSortingStrategy}
                            >
                                {Array.isArray(filteredColumnItems) && filteredColumnItems.map((column, index) => {
                                    const isDraggable = !!(column.colId.toLowerCase() === 'createdate');
                                    const isCompany = column.colId.toLowerCase() === 'company';
                                    return (
                                        !isCompany && <ColumnItems draggable={isDraggable} onChange={handleCurrentColumnCheck} key={index} item={column} />
                                    );
                                })}
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
                <Stack justifyContent="space-between">
                    <Button variant="flat" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleApply}>
                        Apply
                    </Button>
                </Stack>
            </Popover.Content>
        </Popover>
    );
};

export default ReorderColumnsPopover;
