import React, { useEffect } from "react";
import { Dialog } from '@/Components/Feedback';
import { DataTable } from "@/Components/DataDisplay";
import { Button, Checkbox, Select, SelectOption, TextField } from "@/Components/Inputs";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { useState } from "react";
import { requestCompanies, requestKitItems, requestKitsInfo, resetkitState } from "@/Store/Actions";
import { AddKitErrorIcon } from "@/Icons";
import { useDispatch, useSelector } from "@/Store/StoreContextProvider";
import { checkDuplicateSerials, createBatch, createKitAndClaim } from "@/Store/Services";
import { useHistory } from "react-router-dom";
import { BatchType } from "@/Helpers/constants";

const findAllDuplicateCounts = (arr) => {
    let frequency = {};

    arr.forEach((element) => {
        if (frequency[element.itemID]) {
            frequency[element.itemID]++;
        } else {
            frequency[element.itemID] = 1;
        };
    });

    let count = [];
    for (let key in frequency) {
        count.push({ itemID: parseInt(key), count: frequency[key] });
    }
    return count;
};

const KitBatchesTabs = ({ tabs, onChange, value }) => {
    return (
        <div className="flex flex-row overflow-x-auto max-w-[642px]">
            {tabs.map((item) => {
                return (
                    <div 
                        onClick={() => onChange(item?.itemID)}
                        className={`
                            h-8 cursor-pointer px-3 py-2 border border-surface-container-high whitespace-nowrap
                            ${item?.itemID === value ? 'bg-secondary-98 text-inverse-surface' : 'bg-none text-outline'} 
                        `}
                    >
                        {`${item?.itemName} [${item?.quantity} per kit]`}
                    </div>
                );
            })}
        </div>
    );
};

export const AddKitsDialog = ({
    showAddKitsDialog,
    handleBoxSerialKeyDown,
    handleCloseAddKitsDialog,
}) => {

    const [state, setState] = useState({
        companyOnChange: '',
        kitOnChange: '',
        orderNumberOnChange: '',
        tabOnChange: null,
        addItemRowData: [],
        errorMessage: '',
        isLoading: false,
        nonePrimaryItems: [],
        kitsItemToValidate: [],
        isError: false,
        currentItemFocus: null
    });

    const { companyOnChange, kitOnChange, orderNumberOnChange, tabOnChange, addItemRowData, errorMessage, isLoading,
        nonePrimaryItems, kitsItemToValidate, isError, currentItemFocus } = state;

    const history = useHistory();

    const dispatch = useDispatch((dispatch) => dispatch.kit);
    const companyDispatch = useDispatch(dispatch => dispatch?.companies);

    const { user } = useSelector(({ user }) => user);
    const { kit, kitsInfo: kitsInfoTab } = useSelector(({ kit }) => kit);
    const companies = useSelector(({ companies }) => companies?.companies);

    const primaryItem = kitsInfoTab.find(item => item.primary === 1);
    const primaryRequiredQty = primaryItem?.quantity;
    const primaryItemsCount = addItemRowData.filter(item => item.itemID === primaryItem.itemID).length;
    const notPrimaryItems = addItemRowData.filter(item => item.itemID !== primaryItem.itemID);
    const countOfNotPrimaryItems = findAllDuplicateCounts(notPrimaryItems);

    const hasKitItems = !kit?.length > 0;
    const hasInfotabs = !kitsInfoTab?.length > 0;
    const hasOrderNumber = !orderNumberOnChange?.length > 0;
    const hasAdedItems = !addItemRowData?.length > 0;

    const isSameValue = addItemRowData.every((curentItem) => curentItem.serial === curentItem.boxSerial);
    const isValueIsEmpty = addItemRowData.some((curentItem) => curentItem.serial === "" || curentItem.boxSerial === "");

    const handleCheckboxChange = (event) => {
        const id = event.target.name;
        const checked = event.target.checked;
        setState(prevState => ({
            ...prevState,
            addItemRowData: prevState.addItemRowData.map(item =>
                item.id === parseInt(id) ? { ...item, checked: checked } : item
            )
        }));
    };

    const handleAddItem = () => {
        const newId = Math.floor(Math.random() * 9000) + 1000;
        const newItem = {
            itemID: tabOnChange,
            id: parseInt(newId),
            checked: false,
            orderNumber: orderNumberOnChange,
            serial: '',
            boxSerial: '',
            quantity: 1,
            onFocus: false,
            hasSerialInputAlready: false,
            hasBoxSerialInputAlready: false
        };
        setState(prevState => ({ ...prevState, addItemRowData: [...prevState.addItemRowData, newItem], nonePrimaryItems: [] }));
    };
    useEffect(() => {
        setState(prevState => ({ ...prevState, tabOnChange: kitsInfoTab[0]?.itemID }));
    }, [kitsInfoTab]);

    const handleItemsOnChange = (event, field) => {
        const { value, name } = event.target;
        setState(prevState => ({
            ...prevState,
            addItemRowData: prevState.addItemRowData.map(item => {
                let hasSerialInputAlready = item?.hasSerialInputAlready ?? false;
                let hasBoxSerialInputAlready = item?.hasBoxSerialInputAlready ?? false;

                if (field === 'serial') {
                    hasSerialInputAlready = true;
                }

                if (field === 'boxSerial') {
                    hasBoxSerialInputAlready = true;
                }

                return item.id === parseInt(name) ? {
                     ...item, 
                    [event.target.dataset.field]: value,
                    hasSerialInputAlready, 
                    hasBoxSerialInputAlready } : 
                    item
            }),
            nonePrimaryItems: []
        }));

        setState(prevState => ({ ...prevState, currentItemFocus: parseInt(name) }));
    };

    const handleCompanyChange = (event) => {
        const value = event.target.value ?? '';
        setState(prevState => ({ ...prevState, companyOnChange: value }));
        dispatch(requestKitItems(value));
    };

    const handleKitChange = (event) => {
        const value = event.target.value ?? '';
        setState(prevState => ({ ...prevState, kitOnChange: value, addItemRowData: [] }));
        dispatch(requestKitsInfo(value));
    };

    const handleOrderOnChange = (event) => {
        const value = event.target.value ?? '';
        setState(prevState => ({ ...prevState, orderNumberOnChange: value }));
    };

    const handleTabOnChange = (kititemID) => {
        setState(prevState => ({ ...prevState, tabOnChange: kititemID }));
    };

    const handleRemoveItem = () => {
        setState(prevState => ({
            ...prevState,
            addItemRowData: prevState.addItemRowData.filter(item => !item.checked),
            errorMessage: ''
        }));
    };
    const handleResetKitsItems = () => {
        dispatch(resetkitState());
    };

    const handleRedirect = (createdBatch) => {
        history?.push('/kit-details', {
            createdBatch,
            itemID: createdBatch?.testItem?.itemID
        });
    };

    const saveAndClaim = async () => {
        try {
            const MeetTheRequiredQty = addItemRowData.filter(item => item.itemID === primaryItem.itemID).length >= primaryRequiredQty;

            if (!MeetTheRequiredQty) {
                setState(prevState => ({
                    ...prevState,
                    errorMessage: `You must add at least one ${primaryItem.itemName}`,
                    nonePrimaryItems: [],
                }));
                return;
            }
            setState(prevState => ({
                ...prevState,
                nonePrimaryItems: kitsItemToValidate.filter(item => item.primary !== 1).map(item => {
                    let count = (item.quantity - primaryItemsCount) * -1;
                    return {
                        ...item,
                        itemName: item.itemName,
                        missing: count,
                        error: count > 0 ? `You need ${count} more of part "${item.itemName}"` : `You have ${count * -1} many of part "${item.itemName}"`
                    }
                })
            }));
            const isSameCountAsWithPrimary = countOfNotPrimaryItems.every(item => item.count === primaryItemsCount);

            if (MeetTheRequiredQty && isSameCountAsWithPrimary && countOfNotPrimaryItems.length === kitsInfoTab.length - 1) {
                setState(prevState => ({ ...prevState, errorMessage: '', isLoading: true }));
                const newKitItemsList = {};
                addItemRowData.forEach((kit) => {
                    if (!newKitItemsList[kit.itemID]) {
                        newKitItemsList[kit.itemID] = [];
                    }
                    newKitItemsList[kit.itemID].push({
                        id: 0,
                        orderitemID: 0,
                        kitPrimaryItemID: 0,
                        serial: kit.serial,
                        boxserial: kit.boxSerial,
                        itemID: 0,
                        companyID: 0,
                        checklistTemplate: "",
                        optionTemplate: "",
                        creatorID: 0,
                        createDate: null,
                        modDate: null,
                        modUserID: 0,
                        modUserName: "",
                        status: "",
                        usbID: "",
                        stagingStatus: "none",
                        stagingName: "",
                        company: "",
                        accountNumber: "",
                        productID: 0,
                        product: "",
                        commonname: "",
                        fullname: "",
                        checklist: "",
                        files: "",
                        tags: "",
                        options: "",
                        total: 0,
                        qc: "",
                        kitID: kitOnChange,
                        kitName: "",
                        staging: 0,
                        randomAudit: 0,
                        raPercentage: 0,
                        orderNumber: orderNumberOnChange,
                        batchID: 0,
                        auditDate: null,
                        auditUser: 0,
                        shortSerial: ""
                    });
                });

                // Create and claim the kit
                const newKits = {
                    companyID: companyOnChange,
                    kitID: kitOnChange,
                    orderNumber: orderNumberOnChange,
                    userID: user?.userID,
                    items: newKitItemsList
                };
                const createdKitAndClaim = await createKitAndClaim(newKits);

                if (createdKitAndClaim) {
                    // If successful, initiate batch creation
                    const initBatchPayload = {
                        kitID: kitOnChange,
                        orderItems: createdKitAndClaim?.added,
                        userID: user?.userID,
                        batchType: BatchType.KIT
                    };

                    const createdBatch = await createBatch(() => { }, initBatchPayload);
                    if (createdBatch) {
                        handleCloseAddKitsDialog();
                        // Redirect upon successful batch creation
                        handleRedirect(createdBatch);
                        handleResetKitsItems();
                    }
                }
            }
        } catch (error) {
            console.error('Error in saveAndClaim:', error);
        } finally {
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };

    const serialBoxOnBlur = async (event, field) => {
        const id = event?.target?.name;
        const value = event.target?.value || '';

        setState(prevState => ({
            ...prevState,
            addItemRowData: prevState.addItemRowData.map(item =>
                item.id === parseInt(id) ? { ...item, onFocus: false } : item
            )
        }));

        if (field === 'serial' && value) {
            try {
                const isDuplicate = await checkDuplicateSerials(() => { }, {
                    serial: value,
                    itemID: tabOnChange
                });

                if (isDuplicate) {
                    setState(prevState => ({ ...prevState, errorMessage: `Serial number ${value} already exists.` }));
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const serialBoxOnFocus = (event) => {
        const id = event?.target?.name

        setState(prevState => ({
            ...prevState,
            addItemRowData: prevState.addItemRowData.map(item =>
                item.id === parseInt(id) ? { ...item, onFocus: true } : item
            )
        }));
    };

    useEffect(() => {
        (async () => {
            if (!companies) {
                await companyDispatch(requestCompanies());
            }
        })();
    }, [companies, companyDispatch]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, isError: errorMessage.length > 0 }));
    }, [errorMessage]);

    useEffect(() => {
        const getDuplicates = findAllDuplicateCounts(addItemRowData);
        const newItems = kitsInfoTab.map(kit => {
            const item = getDuplicates.find(i => i.itemID === kit.itemID);
            return item ? { ...kit, quantity: item.count } : { ...kit, quantity: 0 };
        });
        setState(prevState => ({ ...prevState, kitsItemToValidate: newItems }));
    }, [kitsInfoTab, addItemRowData]);

    useEffect(() => {
        const isASerialFieldEmpty = addItemRowData?.some(addedItem => 
            addedItem?.hasSerialInputAlready &&
            addedItem?.serial?.length === 0
        );

        const isABoxSerialFieldEmpty = addItemRowData?.some(addedItem => 
            addedItem?.hasBoxSerialInputAlready && 
            addedItem?.boxSerial?.length === 0
        );
        
        const isThereDifferentSerialAndBoxSerial = addItemRowData?.some(addedItem =>
            addedItem?.hasSerialInputAlready &&
            addedItem?.hasBoxSerialInputAlready &&
            addedItem?.serial !== addedItem.boxSerial
        );

        const isBoxSerialOnBlur = addItemRowData?.find(item => item.id === currentItemFocus)?.onFocus;

        if (!isBoxSerialOnBlur) {
            if (isASerialFieldEmpty || isABoxSerialFieldEmpty) {
                setState(prevState => ({ ...prevState, errorMessage: 'One or more required fields are empty.' }));
                return;
            };

            if (isThereDifferentSerialAndBoxSerial) {
                setState(prevState => ({ ...prevState, errorMessage: 'Box Serial does not match item Serial Number.' }));
                return;
            };
        };

        setState(prevState => ({ ...prevState, errorMessage: '' }));
    }, [addItemRowData, currentItemFocus]);

    return (
        <Dialog
            className="h-fit w-[707px] pt-8 px-8 pb-[24px]"
            isOpen={showAddKitsDialog}
            onClose={() => {
                handleCloseAddKitsDialog();
                handleResetKitsItems();
            }}
        >
            <Dialog.Title className=" text-2xl mb-[16px] font-roboto font-medium leading-8 ">
                Add Kits
            </Dialog.Title>
            <Dialog.Content className="border-b-[0px] mb-[16px] border-[#EAEEF3]">
                <div className="space-y-4 mb-8">
                    <Select
                        label="Company"
                        value={companyOnChange}
                        onChange={handleCompanyChange}
                        className="add-kit-select"
                        menuHelperText={(<p className="w-full p-3 text-xs font-medium leading-4 tracking-[0.5px] font-roboto text-on-surface-variant-2 ">Select Company</p>)}

                        searchable
                    >
                        {companies?.map(company => {
                            return (
                                <SelectOption key={company.name} value={company.id} className="font-normal text-[14px] leading-[20px] tracking-[0.25px] !px-[24px]">
                                    {company.name}
                                </SelectOption>
                            );
                        })}
                    </Select>
                    <div className="flex w-full space-x-[18px] mb-[10px]">
                        <div className="w-1/2">
                            <Select
                                label="Kit"
                                className="add-kit-select"
                                value={kitOnChange}
                                menuHelperText={(<p className="w-full p-3 text-xs font-medium leading-4 tracking-[0.5px] font-roboto text-on-surface-variant-2 ">Select Kit</p>)}
                                searchable
                                disabled={hasKitItems}
                                onChange={handleKitChange}
                            >
                                {kit.map((kit) => {
                                    return (
                                        <SelectOption key={kit.name} value={kit.kitID} className="font-normal text-[14px] leading-[20px] tracking-[0.25px] !px-[24px]">
                                            <p>{kit.name}</p>
                                        </SelectOption>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="w-1/2">
                            <TextField onChange={handleOrderOnChange} disabled={hasInfotabs} label="Order Number" className=" w-full add-textfield add-order-number" />
                        </div>
                    </div>

                </div>
                <div className={`${isError ? "flex" : "hidden"} mb-[11.2px] justify-start items-center space-x-2`}>
                    <AddKitErrorIcon className=" text-error" />
                    <p className=" text-xs font-roboto font-semibold leading-4 tracking-[0.5px] text-error ">{errorMessage}</p>
                </div>
                {errorMessage.length === 0 && <>
                    {<div className="mb-[11.2px]">
                        {nonePrimaryItems?.filter(item => item.missing * -1 !== 0).map((error, idx) => {
                            return (
                                <div key={idx} className="flex justify-start items-center space-x-2">
                                    <AddKitErrorIcon className=" text-error" />
                                    <p className=" text-xs font-roboto font-semibold leading-4 tracking-[0.5px] text-error ">{error.error}</p>
                                </div>
                            )
                        })}
                    </div>
                    }
                </>}

                <div className="bg-white border relative border-surface-container-high shadow-custom-light rounded-[6px] w-fit h-[377px]">
                    <div className={` ${orderNumberOnChange.length !== 0 ? "hidden" : ""}  
                        opacity-[0.4] rounded-[5px] absolute z-50 bg-white w-full h-full`} />
                    <DataTable
                        className="add-items-datatable add-kits-datatable"
                        rows={addItemRowData.filter((item) => item.itemID === tabOnChange)}
                        rowRenderer={rowData => {
                            return [
                                <div
                                    className="flex items-center justify-center border-[0.8px] border-surface-container h-[48px] w-full">
                                    <Checkbox name={rowData?.id} checked={rowData?.checked} onChange={handleCheckboxChange} value={rowData?.id} />
                                </div>,
                                <div className="add-kits-textfield">
                                    <TextField onClick={() => {
                                    }}
                                        value={rowData?.serial}
                                        name={rowData?.id}
                                        data-field="serial"
                                        onChange={event => handleItemsOnChange(event, 'serial')}
                                        onBlur={event => serialBoxOnBlur(event, 'serial')}
                                        onFocus={serialBoxOnFocus}
                                        className="add-kit-textfield-select"
                                    />
                                </div>,
                                <div className="add-kits-textfield">
                                    <TextField onClick={() => {
                                    }}
                                        value={rowData?.boxSerial}
                                        name={rowData?.id}
                                        data-field="boxSerial"
                                        onBlur={serialBoxOnBlur}
                                        onFocus={serialBoxOnFocus}
                                        onChange={event => handleItemsOnChange(event, 'boxSerial')}
                                        className="add-kit-textfield-select"
                                    />
                                </div>,
                                <div className="add-kits-textfield">
                                    <TextField value={rowData?.orderNumber} className="add-kit-textfield-select" onKeyDown={handleBoxSerialKeyDown} />
                                </div>,
                            ]
                        }}
                        columns={[
                            {
                                field: '',
                                label: '',
                                width: 48,
                                style: { minWidth: 'unset' },
                                sortable: false
                            },
                            {
                                field: '',
                                label: <p className="">Serial Number</p>,
                                fluid: true,
                                style: { minWidth: 'unset' },
                                sortable: false
                            },
                            {
                                field: '',
                                label: <p className=" ">Box Serial</p>,
                                fluid: true,
                                style: { minWidth: 'unset' },
                                sortable: false
                            },
                            {
                                field: '',
                                label: <p className="">Order Number</p>,
                                fluid: true,
                                style: { minWidth: 'unset' },
                                sortable: false
                            }
                        ]}
                    />
                    <KitBatchesTabs value={tabOnChange} onChange={handleTabOnChange} tabs={kitsInfoTab} />
                </div>
            </Dialog.Content>
            <Dialog.Actions className="justify-between">
                <div className="flex gap-6 ">
                    <Button
                        disabled={hasOrderNumber || isLoading}
                        onClick={handleAddItem}
                    >
                        Add Item(s)
                    </Button>
                    <Button
                        className="border-none"
                        disabled={!addItemRowData.some(rowData => rowData.checked)}
                        variant={!addItemRowData.some(rowData => rowData.checked) ? "flat" : "default"}
                        onClick={handleRemoveItem}
                    >
                        Remove Item
                    </Button>
                </div>
                <div className="flex gap-6">
                    <Button
                        disabled={
                            hasAdedItems ||
                            errorMessage.length > 0 ||
                            isLoading ||
                            isValueIsEmpty ||
                            !isSameValue
                        }
                        isLoading={isLoading}
                        onClick={saveAndClaim}
                    >
                        Save and Claim
                    </Button>
                    <Button variant="flat" onClick={() => {
                        handleCloseAddKitsDialog();
                        handleResetKitsItems()
                    }} >Cancel</Button>
                </div>
            </Dialog.Actions>
        </Dialog>
    );
}